// extracted by mini-css-extract-plugin
export var cell = "styles-module--cell--8707d";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--d9753";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var partition = "styles-module--partition--b200f";
export var sectionHeader1Col = "styles-module--sectionHeader1Col--fb827";
export var sectionHeader2Col = "styles-module--sectionHeader2Col--c00ab";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";