// extracted by mini-css-extract-plugin
export var active = "styles-module--active--eb904";
export var desktop = "1340px";
export var dot = "styles-module--dot--47467";
export var dotContainer = "styles-module--dotContainer--957c4";
export var dotContent = "styles-module--dotContent--6479e";
export var giant = "2200px";
export var grid = "styles-module--grid--8b8f9";
export var hidden = "styles-module--hidden--8a06a";
export var inspector = "styles-module--inspector--9c306";
export var inspectorColumn = "styles-module--inspectorColumn--0cb95";
export var inspectorContent = "styles-module--inspectorContent--f87e7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var matrixGrid = "styles-module--matrixGrid--688d8";
export var matrixRow = "styles-module--matrixRow--17924";
export var maxtrixContent = "styles-module--maxtrixContent--daec3";
export var mobile = "400px";
export var options = "styles-module--options--60d6b";
export var optionsGrid = "styles-module--optionsGrid--729ec";
export var pillButtonsContainer = "styles-module--pillButtonsContainer--de4f6";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";