import React from 'react';
import {
  ColourInspector,
  IGradient,
  LayoutProvider,
  LogoInspector,
  SliceHeader
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { IColor, ISliceStyleInspector } from 'lam-toolkit-figma-plugin';

export const FigmaStyleInspector = ({
  colorHeader,
  colorRows,
  logoHeader,
  logos
}: ISliceStyleInspector) => {
  const gradient: IGradient = {
    category: 'Primary',
    gradient:
      'linear-gradient(90deg, #3F1000 0%, #843300 22%, #C45300 44%, #DD6000 54%, #A94800 68%, #592300 89%, #3A1500 99%)',
    name: 'Copper'
  };

  const customColorRows: (IColor[] | IGradient)[] = [...colorRows, gradient];

  return (
    <LayoutProvider grid paddingX paddingY>
      <div className={styles.logos}>
        <SliceHeader className={styles.sliceHeader} {...logoHeader} />

        <LogoInspector logos={logos} />
      </div>

      <div className={styles.colours}>
        <SliceHeader className={styles.sliceHeader} {...colorHeader} />
        <ColourInspector colorRows={customColorRows} />
      </div>
    </LayoutProvider>
  );
};
