import React from 'react';
import cn from 'classnames';
import {
  LayoutProvider,
  Partition,
  SliceHeader
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';

import { ReactComponent as number0 } from './numberIcons/number0.svg';
import { ReactComponent as number1 } from './numberIcons/number1.svg';
import { ReactComponent as number2 } from './numberIcons/number2.svg';
import { ReactComponent as number3 } from './numberIcons/number3.svg';
import { ReactComponent as number4 } from './numberIcons/number4.svg';
import { ReactComponent as number5 } from './numberIcons/number5.svg';
import { ReactComponent as number6 } from './numberIcons/number6.svg';
import { ReactComponent as number7 } from './numberIcons/number7.svg';
import { ReactComponent as number8 } from './numberIcons/number8.svg';
import { ReactComponent as number9 } from './numberIcons/number9.svg';

export const numberIcons = Object.freeze({
  number0,
  number1,
  number2,
  number3,
  number4,
  number5,
  number6,
  number7,
  number8,
  number9
});

type TNumberIcon = keyof typeof numberIcons;

export const FigmaStrategyNumberList2 = () => {
  return (
    <LayoutProvider paddingX className={styles.container}>
      <SliceHeader heading={{ content: 'Traits', typestyle: 'h2' }} />
      <NumberSection
        numberIcon="number1"
        header="We work tirelessly to do things right."
        body="We raise our hands whenever we see something that is not as it should be. We walk the talk, we live up to our commitments and only promise what we know we can achieve. We are honest and transparent every day.  We go the extra mile if it means doing things that we can be proud of."
      />
      <NumberSection
        numberIcon="number2"
        header="We take ownership of everything we do."
        body="We always hold ourselves accountable for team outcomes, good or bad, ensuring our shared success. We always come up with solutions instead of dwelling or trying to justify the problem. We lead by example, embodying our values and behaviours, and are never afraid to ask for support when we need it. We plan with detail and execute with precision, always anticipating challenges."
      />
      <NumberSection
        numberIcon="number3"
        header="We push ourselves and our clients further."
        body="We’re comfortable getting out of our comfort zone in order to continuously improve. We value the feedback we receive as a way to grow to become the best versions of ourselves. We confidently venture into the unknown, knowing that we have the tools to meet our clients’ evolving needs. We believe in everyone’s potential and give them the tools and trust to thrive."
      />
      <NumberSection
        numberIcon="number4"
        header="We always get results that matter."
        body="We value and prioritize results achieved, not how much effort was put in. We’re laser-focused on making sure our work is best-in-class. We fulfil our purpose to serve our customers, no matter what team or geography we are in. We look for the results that add the most value for all our stakeholders."
      />
      <NumberSection
        numberIcon="number5"
        header="We work together to achieve great things."
        body="We're all part of the same team, prioritising the collective success over personal egos. We succeed and fail together, making sure we bring out the best in each other. We support one another, creating a work environment where everyone can thrive. We own our mistakes, recognising them as crucial for our shared success."
      />
    </LayoutProvider>
  );
};

const NumberSection = ({
  numberIcon,
  body,
  header
}: {
  numberIcon: TNumberIcon;
  header: string;
  body: string;
}) => {
  const SVGElement = numberIcons[numberIcon];

  return (
    <LayoutProvider grid className={styles.section}>
      <div className={styles.iconContainer}>
        <SVGElement className={styles.icon} />
      </div>
      <div className={styles.text}>
        <h3 className={cn('h3', styles.header)}>{header}</h3>
        <p className={cn('b1', styles.body)}>{body}</p>
      </div>

      <Partition className={styles.partition} />
    </LayoutProvider>
  );
};
