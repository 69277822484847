import React from 'react';

import { ReactComponent as agreement } from './icons/agreement.svg';
import { ReactComponent as arrow } from './icons/arrow.svg';
import { ReactComponent as bessV2 } from './icons/bess-v2.svg';
import { ReactComponent as bess } from './icons/bess.svg';
import { ReactComponent as brainstorm } from './icons/brainstorm.svg';
import { ReactComponent as calendar } from './icons/calendar.svg';
import { ReactComponent as collaboration } from './icons/collaboration.svg';
import { ReactComponent as connect } from './icons/connect.svg';
import { ReactComponent as cross } from './icons/cross.svg';
import { ReactComponent as deadline } from './icons/deadline.svg';
import { ReactComponent as documents } from './icons/documents.svg';
import { ReactComponent as find } from './icons/find.svg';
import { ReactComponent as global } from './icons/global.svg';
import { ReactComponent as h2 } from './icons/h2.svg';
import { ReactComponent as idea } from './icons/idea.svg';
import { ReactComponent as manual } from './icons/manual.svg';
import { ReactComponent as minus } from './icons/minus.svg';
import { ReactComponent as monthlySum } from './icons/monthly-sum.svg';
import { ReactComponent as navigation } from './icons/navigation.svg';
import { ReactComponent as objective } from './icons/objective.svg';
import { ReactComponent as plus } from './icons/plus.svg';
import { ReactComponent as process } from './icons/process.svg';
import { ReactComponent as renewable } from './icons/renewable.svg';
import { ReactComponent as resultsV2 } from './icons/results-v2.svg';
import { ReactComponent as results } from './icons/results.svg';
import { ReactComponent as security } from './icons/security.svg';
import { ReactComponent as solarPV } from './icons/solar-pv.svg';
import { ReactComponent as tick } from './icons/tick.svg';
import { ReactComponent as transaction } from './icons/transaction.svg';
import { ReactComponent as wind } from './icons/wind.svg';

export const brandIcons = Object.freeze({
  Agreement: agreement,
  Arrow: arrow,
  Bess: bess,
  'Bess V2': bessV2,
  Brainstorm: brainstorm,
  Calendar: calendar,
  Collaboration: collaboration,
  Connect: connect,
  Cross: cross,
  Deadline: deadline,
  Documents: documents,
  Find: find,
  Global: global,
  H2: h2,
  Idea: idea,
  Manual: manual,
  Minus: minus,
  'Monthly Sum': monthlySum,
  Navigation: navigation,
  Objective: objective,
  Plus: plus,
  Process: process,
  Renewable: renewable,
  Results: results,
  'Results V2': resultsV2,
  Security: security,
  'Solar PV': solarPV,
  Tick: tick,
  Transaction: transaction,
  Wind: wind
});

export type TBrandIcon = keyof typeof brandIcons;

export interface IBrandIcon {
  icon: TBrandIcon;
  className?: string;
  style?: React.CSSProperties;
}

export const BrandIcon = ({ icon, ...props }: IBrandIcon) => {
  if (!brandIcons[icon]) return null;

  const SVGElement = brandIcons[icon];

  return <SVGElement {...props} aria-hidden />;
};
