// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var gridContainer = "styles-module--gridContainer--1a177";
export var gridContent = "styles-module--gridContent--829d7";
export var image = "styles-module--image--7842d";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var pillButtons = "styles-module--pillButtons--61a07";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";