// extracted by mini-css-extract-plugin
export var background = "styles-module--background--61b3f";
export var blur = "styles-module--blur--5c48d";
export var container = "styles-module--container--b1cb8";
export var content = "styles-module--content--a957a";
export var desktop = "1340px";
export var giant = "2200px";
export var gradient = "styles-module--gradient--3b5ed";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--4cea9";
export var mobile = "400px";
export var mobileWidget = "styles-module--mobileWidget--496ae";
export var scrollPadding = "styles-module--scrollPadding--6a3d8";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--2e4d8";
export var textWrapper = "styles-module--textWrapper--e6a2e";