import { IPageData } from 'lam-toolkit-figma-plugin';
import { useMVT } from '../stores';

interface IPageCategory {
  title: string;
  pages: IPageData[];
}

export const usePages = () => {
  const { pageData } = useMVT();

  const getPageCategories: () => IPageCategory[] = () => {
    const pageCategories: IPageCategory[] = [];

    pageData.forEach((page) => {
      const pageCategory = page.category;

      if (!pageCategory) {
        return;
      }

      const existingCategory = pageCategories.find(
        (category) => category.title === pageCategory
      );

      if (existingCategory) {
        existingCategory.pages.push(page);
      } else {
        pageCategories.push({
          title: page.category || '',
          pages: [page]
        });
      }
    });

    return pageCategories;
  };
  const pageCategories = getPageCategories();

  // todo - better sorting method
  const sortedPageCategories = pageCategories?.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return {
    pageCategories: sortedPageCategories
  };
};
