import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  LayoutProvider,
  TAnimation,
  TextAnimation
} from '@lam-agency/toolkit/components';
import {
  useBreakpoints,
  useMousePosition,
  useScroll,
  useWindowDimensions
} from '@lam-agency/toolkit/hooks';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';
import { gsap } from 'gsap';

export const FigmaGradientAtf = () => {
  const [contentHeight, setContentHeight] = useState(9999); // Prevent scroll animations from triggering on slices below before content is loaded
  const contentRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const { height } = useWindowDimensions();
  const { smallDesktop } = useBreakpoints();
  const { x: mouseX } = useMousePosition();

  const gradientRef = useRef<HTMLDivElement>(null);

  const contentOverflowHeight = Math.max(0, contentHeight - height);
  const contentTransformY = smallDesktop
    ? -Math.min(contentOverflowHeight, scrollY)
    : 0;

  const amountToScrollBeforeBlurPx = 300; // sync with amoutToScrollBeforeFill in Header.tsx
  const blurMultiplier = 0.02;
  const normalisedScroll = Math.max(0, scrollY - amountToScrollBeforeBlurPx);
  const blurAmountPx = normalisedScroll * blurMultiplier;

  const darkenMultiplier = 0.002;
  const maxDarkenOpacity = 0.75;
  const darkenOpacity = Math.min(
    maxDarkenOpacity,
    normalisedScroll * darkenMultiplier
  );

  const textAnimation = getCSSVariableValue(
    '--atf-text-animation'
  ) as TAnimation;

  useEffect(() => {
    const calculateContentHeight = () => {
      if (!contentRef.current) return;

      const containerHeight = contentRef.current.clientHeight;
      setContentHeight(containerHeight);
    };

    setTimeout(calculateContentHeight, 100);

    window.addEventListener('resize', calculateContentHeight);

    return () => {
      window.removeEventListener('resize', calculateContentHeight);
    };
  }, []);

  useEffect(() => {
    if (gradientRef.current) {
      gsap.to(gradientRef.current, {
        x: mouseX - window.innerWidth / 2,
        duration: 3,
        ease: 'power2.out'
      });
    }
  }, [mouseX]);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.content}
          ref={contentRef}
          style={{ transform: `translateY(${contentTransformY}px)` }}
        >
          <div ref={gradientRef} className={styles.gradient} />

          <LayoutProvider grid paddingX paddingY className={styles.layout}>
            <div className={styles.textWrapper}>
              <TextAnimation
                className={cn('d1')}
                text="Gradients"
                animation={textAnimation}
                speed={0.03}
              />
            </div>
          </LayoutProvider>

          <div
            aria-hidden
            style={{
              backdropFilter: `blur(${blurAmountPx}px)`,
              backgroundColor: `rgba(0, 0, 0, ${darkenOpacity})`
            }}
            className={styles.blur}
          />
        </div>
      </div>
      <div
        aria-hidden
        style={{ height: contentHeight }}
        className={styles.scrollPadding}
      />
    </>
  );
};
