import React from 'react';
import cn from 'classnames';
import { LayoutProvider, Partition } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';

import { ReactComponent as number0 } from './numberIcons/number0.svg';
import { ReactComponent as number1 } from './numberIcons/number1.svg';
import { ReactComponent as number2 } from './numberIcons/number2.svg';
import { ReactComponent as number3 } from './numberIcons/number3.svg';
import { ReactComponent as number4 } from './numberIcons/number4.svg';
import { ReactComponent as number5 } from './numberIcons/number5.svg';
import { ReactComponent as number6 } from './numberIcons/number6.svg';
import { ReactComponent as number7 } from './numberIcons/number7.svg';
import { ReactComponent as number8 } from './numberIcons/number8.svg';
import { ReactComponent as number9 } from './numberIcons/number9.svg';

export const numberIcons = Object.freeze({
  number0,
  number1,
  number2,
  number3,
  number4,
  number5,
  number6,
  number7,
  number8,
  number9
});

type TNumberIcon = keyof typeof numberIcons;

export const FigmaStrategyNumberList = () => {
  return (
    <LayoutProvider paddingX className={styles.container}>
      <NumberSection
        numberIcon="number1"
        header="We work together to achieve great things"
        body="We show dedication and commitment, learn from our mistakes, and go beyond our individual work."
      />
      <NumberSection
        numberIcon="number2"
        header="We take ownership of everything we do"
        body="We continuously learn and improve, foster innovation and creativity, take the initiative, and are always on beta mode."
      />
      <NumberSection
        numberIcon="number3"
        header="We push ourselves and our clients further"
        body="We find the right solution, focus on results, and understand our clients' needs empathetically."
      />
      <NumberSection
        numberIcon="number4"
        header="We get results that matter"
        body="We see each task, project or result as a collective effort, fostering a culture of shared success and mutual respect."
      />
      <NumberSection
        numberIcon="number5"
        header="We work tirelessly to do things right"
        body="We are honest and transparent, work to fulfill our purpose, and always go the extra mile to do things right."
      />
    </LayoutProvider>
  );
};

const NumberSection = ({
  numberIcon,
  body,
  header
}: {
  numberIcon: TNumberIcon;
  header: string;
  body: string;
}) => {
  const SVGElement = numberIcons[numberIcon];

  return (
    <LayoutProvider grid className={styles.section}>
      <div className={styles.iconContainer}>
        <SVGElement className={styles.icon} />
      </div>
      <div className={styles.text}>
        <h3 className={cn('h3', styles.header)}>{header}</h3>
        <p className={cn('b1', styles.body)}>{body}</p>
      </div>

      <Partition className={styles.partition} />
    </LayoutProvider>
  );
};
