// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--f82a8";
export var icon = "styles-module--icon--7e3a1";
export var iconContainer = "styles-module--iconContainer--b378a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var partition = "styles-module--partition--8393e";
export var section = "styles-module--section--3cbfd";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--eea87";