import React from 'react';
import { LayoutProvider, Partition } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import cn from 'classnames';

export const FigmaTable = () => {
  return (
    <LayoutProvider className={styles.container} paddingX paddingY>
      <Table>
        <TableSectionHeader
          className={styles.sectionHeader1Col}
          text="Page Margins and Gutters - Print"
        />
        <TableSectionHeader
          className={styles.sectionHeader2Col}
          text="This table outlines the page margins and gutters for commonly used print sizes."
        />

        <CellTitle text="Page Size" />
        <CellTitle text="Margins" />
        <CellTitle text="Gutters" />

        <CellData text="105x148mm (A6)" />
        <CellData text="6.5mm" />
        <CellData text="2.5mm" />

        <CellData text="148x210mm (A5)" />
        <CellData text="8.5mm" />
        <CellData text="3.5mm" />

        <CellData text="210x297mm (A4)" />
        <CellData text="10mm" />
        <CellData text="4.5mm" />

        <CellData text="297x420mm (A3)" />
        <CellData text="12.5mm" />
        <CellData text="5.5mm" />

        <CellData text="420x594mm (A2)" />
        <CellData text="20mm" />
        <CellData text="7.5mm" />

        <CellData text="594x841mm (A1)" />
        <CellData text="30mm" />
        <CellData text="10mm" />

        <CellData text="841x1189mm (A0)" />
        <CellData text="40mm" />
        <CellData text="15mm" />
      </Table>

      <Table>
        <TableSectionHeader
          className={styles.sectionHeader1Col}
          text="Page Margins and Gutters - Digital"
        />
        <TableSectionHeader
          className={styles.sectionHeader2Col}
          text="This table outlines the page margins and gutters for commonly used digital sizes."
        />

        <CellTitle text="Page Size" />
        <CellTitle text="Margins" />
        <CellTitle text="Gutters" />

        <CellData text="1920x1080px (16:9)" />
        <CellData text="1080x1080px (1:1)" />
        <CellData text="1024x768px (4:3)" />

        <CellData text="60px" />
        <CellData text="60px" />
        <CellData text="50px" />

        <CellData text="20px" />
        <CellData text="20px" />
        <CellData text="20px" />
      </Table>
    </LayoutProvider>
  );
};

const TableSectionHeader = ({
  className,
  text
}: {
  text: string;
  className?: string;
}) => {
  return <h3 className={cn('b1', className)}>{text}</h3>;
};

const CellTitle = ({
  className,
  text
}: {
  text: string;
  className?: string;
}) => {
  return <h4 className={cn('b2', styles.cell, className)}>{text}</h4>;
};

const CellData = ({
  className,
  text
}: {
  text: string;
  className?: string;
}) => {
  return <p className={cn('h3', styles.cell, className)}>{text}</p>;
};

const Table = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Partition className={styles.partition} />
      <div className={styles.grid}>{children}</div>
    </>
  );
};
