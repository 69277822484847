import React, { useEffect, useRef, useState } from 'react';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import {
  ISlidePillButtonOption,
  LayoutProvider,
  PreviewGridButton,
  SliceHeader,
  SlidePillButtons,
  TextAnimation
} from '@lam-agency/toolkit/components';
import { ISliceHeader } from 'lam-toolkit-figma-plugin';
import { type TDotIcon, dotIcons } from './icons';
import { type TDotNumber, dotNumbers } from './numbers';
import { useMousePosition } from '@lam-agency/toolkit/hooks';

type TMode = 'icons' | 'numbers';
type TModeOptions = typeof dotIcons & typeof dotNumbers;

export const FigmaDotIcons = ({
  sliceHeader
}: {
  sliceHeader: ISliceHeader;
}) => {
  const [activeSymbolIndex, setActiveSymbolIndex] = useState(0);
  const [pillButtons, setPillButtons] = useState<ISlidePillButtonOption[]>([
    { active: true, label: 'Icons', value: 'icons' },
    { active: false, label: 'Numbers', value: 'numbers' }
  ]);

  const activeMode = pillButtons.find((button) => button.active)
    ?.value as TMode;

  const dotIconNames = Object.keys(dotIcons) as TDotIcon[];
  const activeIconName = dotIconNames[activeSymbolIndex];
  const activeIcon = dotIcons[activeIconName];

  const dotNumberNames = Object.keys(dotNumbers) as TDotNumber[];
  const activeNumberName = dotNumberNames[activeSymbolIndex];
  const activeNumber = dotNumbers[activeNumberName];

  const activeSymbolName =
    activeMode === 'icons' ? activeIconName : activeNumberName;

  const activeDotMatrix = activeMode === 'icons' ? activeIcon : activeNumber;

  const modeOptionNames =
    activeMode === 'icons' ? dotIconNames : dotNumberNames;
  const modeOptions: TModeOptions = {
    ...dotIcons,
    ...dotNumbers
  };

  const { x: mouseX, y: mouseY } = useMousePosition();

  const dotRefs = useRef<(HTMLDivElement | null)[][]>([]);

  useEffect(() => {
    const DISTANCE_FROM_CURSOR_PX = 100;
    const MAX_SCALE = 3;

    if (!dotRefs.current.length) return;

    dotRefs.current.forEach((row) => {
      row.forEach((dotRef) => {
        if (!dotRef) return;

        if (dotRef.dataset.active === 'true') {
          dotRef.style.transform = 'scale(1)';
          return;
        }

        const rect = dotRef.getBoundingClientRect();
        const dx = rect.left + rect.width / 2 - mouseX;
        const dy = rect.top + rect.height / 2 - mouseY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance <= DISTANCE_FROM_CURSOR_PX) {
          const scale =
            1 + (MAX_SCALE - 1) * (1 - distance / DISTANCE_FROM_CURSOR_PX);
          dotRef.style.transform = `scale(${scale})`;
        } else {
          dotRef.style.transform = 'scale(1)';
        }
      });
    });
  }, [mouseX, mouseY]);

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader {...sliceHeader} />

      <div className={cn('caption', styles.pillButtonsContainer)}>
        <SlidePillButtons options={pillButtons} setOptions={setPillButtons} />
      </div>

      <LayoutProvider grid className={styles.grid}>
        <div className={styles.options}>
          <div className={styles.optionsGrid}>
            {modeOptionNames.map((symbol, i) => (
              <PreviewGridButton
                key={i}
                active={activeSymbolName === symbol}
                onClick={() => setActiveSymbolIndex(i)}
              >
                <DotIcon icon={modeOptions[symbol]} />
              </PreviewGridButton>
            ))}
          </div>
        </div>

        <div className={styles.inspectorColumn}>
          <div className={styles.inspector}>
            <div className={styles.inspectorContent}>
              <div className={styles.matrixGrid}>
                <div className={styles.maxtrixContent}>
                  {activeDotMatrix.map((row, i) => (
                    <div key={i} className={styles.matrixRow}>
                      {row.map((dot, j) => (
                        <div
                          key={j}
                          className={cn(styles.dotContainer, {
                            [styles.active]: dot
                          })}
                        >
                          <div className={styles.dotContent}>
                            <div
                              data-active={dot ? true : false}
                              ref={(el) => {
                                if (!dotRefs.current[i]) {
                                  dotRefs.current[i] = [];
                                }
                                dotRefs.current[i][j] = el;
                              }}
                              style={
                                {
                                  '--dotDelay': `${i + j * 10}ms`
                                } as React.CSSProperties
                              }
                              className={styles.dot}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <TextAnimation
            text={activeSymbolName}
            animation="typed"
            element="h2"
            className="h2"
            trigger
          />
        </div>
      </LayoutProvider>
    </LayoutProvider>
  );
};

const DotIcon = ({ icon }: { icon: number[][] }) => {
  return (
    <div className={styles.matrixGrid}>
      <div className={styles.maxtrixContent}>
        {icon.map((row, i) => (
          <div key={i} className={styles.matrixRow}>
            {row.map((dot, j) => (
              <div
                key={j}
                className={cn(styles.dotContainer, {
                  [styles.active]: dot,
                  [styles.hidden]: !dot
                })}
              >
                <div className={styles.dotContent}>
                  <div
                    style={
                      {
                        '--dotDelay': `${i + j * 10}ms`
                      } as React.CSSProperties
                    }
                    className={styles.dot}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
