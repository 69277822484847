import React, { useState } from 'react';
import {
  ISlidePillButtonOption,
  LayoutProvider,
  SliceHeader,
  SlidePillButtons,
  TwoColumnText
} from '@lam-agency/toolkit/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useWindowDimensions } from '@lam-agency/toolkit/hooks';

import mobileLandscape from './images/360:landscape.jpg';
import mobilePortrait from './images/360:portrait.jpg';
import mobileSquare from './images/360:square.jpg';
import tabletLandscape from './images/768:landscape.jpg';
import tabletPortrait from './images/768:portrait.jpg';
import tabletSquare from './images/768:square.jpg';
import desktopLandscape from './images/1440:landscape.jpg';
import desktopPortrait from './images/1440:portrait.jpg';
import desktopSquare from './images/1440:square.jpg';

export const FigmaGrid = () => {
  const [pillButtons, setPillButtons] = useState<ISlidePillButtonOption[]>([
    { active: true, label: 'Landscape', value: 'landscape' },
    { active: false, label: 'Portrait', value: 'portrait' },
    { active: false, label: 'Square', value: 'square' }
  ]);

  const { width } = useWindowDimensions();

  const getActiveImage = () => {
    const activeGrid = pillButtons.find((button) => button.active)?.value;
    if (!activeGrid) return '';

    switch (activeGrid) {
      case 'landscape':
        if (width < 768) {
          return mobileLandscape;
        } else if (width < 1440) {
          return tabletLandscape;
        } else return desktopLandscape;
      case 'portrait':
        if (width < 768) {
          return mobilePortrait;
        } else if (width < 1440) {
          return tabletPortrait;
        } else return desktopPortrait;
      case 'square':
        if (width < 768) {
          return mobileSquare;
        } else if (width < 1440) {
          return tabletSquare;
        } else return desktopSquare;

      default:
        return '';
    }
  };
  const activeImage = getActiveImage();

  return (
    <LayoutProvider paddingX className={styles.container}>
      <SliceHeader heading={{ content: 'Grid', typestyle: 'h2' }} />
      <TwoColumnText
        heading={{ content: 'Responsive Layouts', typestyle: 'b1' }}
        secondaryHeading={{ content: '', typestyle: '' }}
        subheading={{ content: '', typestyle: '' }}
        body={{
          content:
            'The grid for all applications is 10 columns and rows. The grid is inspired from the five circles within the symbol.\n\nUse just five columns to create a clear distinction with the brand symbol, or use all 10 columns for greater flexibility.\n\nInspired by the movement of the symbol, the fact that the grid moves and adapts depending on the format creates a sense of visual movement through the various designs, which is a distinctive element of the Zelestra design system.',
          typestyle: 'b1'
        }}
      />

      <div className={styles.gridContainer}>
        <div className={styles.gridContent}>
          {activeImage && (
            <img className={styles.image} src={activeImage} alt="Grid" />
          )}

          <SlidePillButtons
            className={styles.pillButtons}
            options={pillButtons}
            setOptions={setPillButtons}
          />
        </div>
      </div>

      <p className={cn('b2')}>
        These examples demonstrate how the grid works on various formats.
      </p>
    </LayoutProvider>
  );
};
